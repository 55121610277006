<template>
  <div class="tab">
    <div class="tab-header y-pointer">
      <ul class="tab-item" ref="nav">
        <li
            :class="tabCls(item)"
            v-for="(item, index) in menu"
            :key="index"
            @click="handleChange(index)"
        >
          {{item.title}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "YRouteTabs",
  props:{
    menu:{
      type:Array,
      default(){
        return []
      },
      required:true,
    }
  },
  methods:{
    handleChange(index){
      this.$router.push(this.menu[index]?.path);
    },
    tabCls(item) {
      return [
        `tab-item-title`,
        {
          [`tab-active`]: item?.path?.name === this.$route.name
        }
      ];
    },
  }
}
</script>

<style scoped>
@import "base.css";
</style>