<template>

  <y_layout>
    <YRouteTabs :menu="[
        {title:'公众号',path:{name:'channelApp',params:$route.params}},
        {title:'小程序',path: {name:'channelWxapp',params:$route.params}},
        {title:'支付',path: {name:'channelWxpay',params:$route.params}},
    ]"></YRouteTabs>
    <div style="margin-top: .5rem"><router-view></router-view></div>
<!--    <el-row>-->
<!--      <el-col :span="1">-->
<!--        <div class="flex-def flex-zEnd" style="width: 100%;margin-top: 2.5rem">-->
<!--          <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom tab-box">-->
<!--            <div :class="$route.name === 'channelApp' ? 'active' : ''" @click="$router.push({name:'channelApp',params:$route.params})" class="y-pointer" style="padding: 1rem 0;">公<br>众<br>号</div>-->
<!--            <div :class="$route.name === 'channelWxapp' ? 'active' : ''" @click="$router.push({name:'channelWxapp',params:$route.params})" class="y-pointer" style="padding: 1rem 0;border-top: 1px solid #ededed">小 <br>程 <br>序</div>-->
<!--            <div :class="$route.name === 'channelWxpay' ? 'active' : ''" @click="$router.push({name:'channelWxpay',params:$route.params})" class="y-pointer" style="padding: 1rem 0;border-top: 1px solid #ededed">支 <br>付 </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-col>-->
<!--      <el-col :span="23">-->
<!--        <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;min-height: 35rem">-->
<!--          <router-view/>-->
<!--        </el-card>-->
<!--      </el-col>-->
<!--    </el-row>-->
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import YRouteTabs from "@/components/YTabs/YRouteTabs";

export default {
  name: "index",
  components: {YRouteTabs, y_layout},
}
</script>

<style scoped>

.y-pointer:hover,
.active {
  color: #5D60F6;
}

.tab-box {
  border-radius: 10px 0 0 10px;
  border: 1px solid #ededed;
  font-size: 12px;
  background-color: #FFFFFF;
  width: 2rem;
  color: #888888;
  font-weight: 600;
}
</style>